<template>
  <div class="allclass" v-wechat-title="$route.meta.title">
    <navigation :msg="msg"></navigation>
    <div class="paytip">
      <p>核实个人信息</p>
      <p class="redmsg">您的个人信息将被严格保密，仅用于此次信息核实。</p>
    </div>
    <div>
      <div class="xinxi">
        <p><span>*</span>姓名</p>
        <div>
          <input placeholder="如填写信息有误，将影响核实结果" v-model="name" />
        </div>
      </div>
      <div style="background-color: #fff2ed" class="xinxi">
        <p><span>*</span>手机</p>
        <div v-show="disabled == 'disabled'">
          <input
            style="background: transparent"
            disabled
            placeholder="授权短信接受号码，仅限候选人本人"
            v-model="mobile"
          />
        </div>
        <div v-show="disabled != 'disabled'">
          <input placeholder="请填写实名认证过的手机号" v-model="mobile" />
        </div>
      </div>
      <div class="xinxi">
        <p><span>*</span>身份证号</p>
        <div>
          <input placeholder="如填写信息有误，将影响核实结果" v-model="id_no" />
        </div>
      </div>
      <div v-show="iscertId == true" class="xinxi">
        <p>学历证书编号(毕业证书)</p>
        <div>
          <input
            placeholder="不填或者编号错误，将影响核实结果"
            v-model="certId"
          />
        </div>
      </div>
	  <div  v-show="isworkingCompany == true" class="xinxi">
	    <p>目前是否工作</p>
	    <div>
			<van-radio-group v-model="ifjobradio" direction="horizontal">
			  <van-radio name="1" checked-color="#EB4E36">是</van-radio>
			  <van-radio name="2" checked-color="#EB4E36">否</van-radio>
			</van-radio-group>
	    </div>
	  </div>
	  <div v-show="ifjobradio == 1 &&isworkingCompany == true" class="xinxi">
	    <p>就职公司</p>
	    <div>
	      <input
		  placeholder="请输入就职公司"
	        v-model="jobname"
	      />
	    </div>
	  </div>
	  <!-- isworkingCompany -->
      <div v-show="iscertCode == true" class="xinxi">
        <p>资格证书</p>
        <div><input placeholder="内容" v-model="certCode" /></div>
      </div>
      <div v-show="ispassport_number == true" class="xinxi">
        <p>护照编码</p>
        <div><input placeholder="内容" v-model="passport_number" /></div>
      </div>
      <!-- <van-cell is-link @click="showPopup" closeable="false"
        ><span>*</span>请需知</van-cell
      > -->
      <div class="title">提示</div>
      <div class="tipcalss">
        1：请确认手机号为被调人实名号码，否则将无法生成报告<br />
        2：请确认填写信息无误，点击底部“同意”后链接将失效
      </div>
    </div>
    <div class="buall">
      <button @click="refuse" class="bton1 bt" id="bt1">拒绝</button>
      <button @click="go" class="bton bt">同意</button>
    </div>

    <van-popup v-model="showhua">
      <!-- <huakuai @para="para"></huakuai> -->
      <van-field
        v-model="mobile_code"
        center
        clearable
        type="number"
        maxlength="6"
        label="短信验证码"
        placeholder="请输入短信验证码"
      >
        <template #button>
          <van-button @click="sencode" size="mini" type="primary">{{
            getcode
          }}</van-button>
        </template>
      </van-field>
      <!-- <van-button type="info">确认验证</van-button> -->
      <div class="querenclass">
        <van-button @click="tijiao" block type="info">确认提交</van-button>
      </div>
    </van-popup>
    <mskalert
      v-on:ok="ok"
      v-on:modifyismsk="modifyismsk"
      :ismsk="ismsk"
    ></mskalert>

    <van-popup class="box1" v-model="show" closeable :style="{ height: '20%' }"
      >本次含有【学历】核验，请准备好学历编号，并正确填写。
    </van-popup>
  </div>
</template>
<script>
import mskalert from "../mskalert/index.vue";
import base from "../../../util/base";
import huakuai from "../../components/huakuai/index";
import { Dialog } from "vant";
import { Toast } from "vant";
const MAX_TIME = 3; //分钟
export default {
  components: {
    mskalert,
    huakuai,
  },
  data() {
    return {
      showhua: false,
      msg: "完善信息",
      // 请求参数信息<<<<<<<<<<<<<<script
      name: "",
      mobile: "17630585210",
      id_no: "",
      certId: "",
      certCode: "",
      passport_number: "",
      allmsg: "", //接收到所有值
      ismsk: false,
      disabled: "disabled", //是否禁用输入框
      iscertId: "", // 是否显示学历编号
      iscertCode: "", //是否显示资格证书
      ispassport_number: "", //是否显示护照编码
      lock: true, //防止多次点击
      mobile_code: "",
      getcode: "获取验证码", //倒计时
      intervalID: "",
      show: false,
	  isworkingCompany:false,
	  ifjobradio:"1",
	  jobname:'',
    };
  },
  watch: {
    showhua() {
      if (this.showhua == false) {
        this.getcode = "获取验证码";
      }
    },
  },
  mounted() {
    // if (!this.$route.params.allmsg) {
    //   this.$router.go(-1);
    //   return;
    // }
    // this.fileterProject(JSON.parse(this.$route.params.allmsg.set_id));
    if (!this.$route.params.allmsg) {
      this.fileterProject(JSON.parse(this.$route.params.allmsg.set_id));
      Dialog({
        message: "安全提醒：页面已失效，请根据授权信息重新载入.",
      });
      return;
    }
    this.allmsg = this.$route.params.allmsg;
    this.name = this.allmsg.name;
    this.mobile = this.allmsg.mobile;
    this.id_no = this.allmsg.id_no;
    if (this.allmsg.set_id) {
      this.fileterProject(JSON.parse(this.allmsg.set_id));
    }

    if (this.iscertId) {
      this.certId = this.allmsg.certId;
      // this.show = true;
      Dialog.alert({
        message: "本次含有【学历】核验，请准备好学历编号，并正确填写。",
      }).then(() => {
        // on close
      });
    }
    if (this.iscertCode) {
      this.certCode = this.allmsg.certCode;
    }
    if (this.ispassport_number) {
      this.passport_number = this.allmsg.passport_number;
    }

    if (this.mobile == "" || this.mobile == undefined) {
      this.disabled = "";
    }
  },

  methods: {
    showPopup() {
      this.show = true;
    },
    //三分钟验证（防止重复提交验证）
    timeVerification() {
      let bcti = sessionStorage.getItem("backcenter_time");
      if (bcti) {
        if (new Date().getMinutes() > bcti || new Date().getMinutes() == bcti) {
          let nowTimeMinutes = new Date().getMinutes();
          let oldTimeMinutes = nowTimeMinutes + MAX_TIME;
          sessionStorage.setItem("backcenter_time", oldTimeMinutes);
          return true;
        } else {
          let thetime = Number(bcti) - Number(new Date().getMinutes());
          this.$toast("请在" + thetime + "分钟后再次提交");
          return false;
        }
      } else {
        let nowTimeMinutes = new Date().getMinutes();
        let oldTimeMinutes = nowTimeMinutes + MAX_TIME;
        sessionStorage.setItem("backcenter_time", oldTimeMinutes);
        return true;
      }
    },
    tijiao() {
      if (this.mobile_code == "") {
        this.$toast("请输入验证码");
        return;
      }
      this.showhua = false;
      this.go1();
    },
    //发送短信
    sencode() {
      if (this.getcode == "获取验证码") {
        this.timeing();
        this.sendcodemsg();
      }
    },
    /**
     * 请求短信接口数据
     */
    sendcodemsg() {
      this.$toast.loading();
      this.$http
        .post("/firm/v1/Login/authentication", {
          reqType: "userinfo",
          mobile: this.mobile,
          name: this.name,
          id_no: this.id_no,
        })
        .then((res) => {
          if (JSON.parse(res.data).msg) {
            this.$toast("发送成功");
            this.timeing();
            this.showhua = true;
          }
        });
    },
    /**
     * 计时器
     */
    timeing() {
      this.getcode = 60;
      this.intervalID = setInterval(() => {
        this.getcode--;

        if (this.getcode < 1) {
          clearInterval(this.intervalID);
          this.getcode = "获取验证码";
        }
      }, 1000);
    },

    /**
     * 停止计时器
     */
    destroyed() {
      clearInterval(this.intervalID);
    },
    para(msg) {},
    /**
     * 查询展示
     * 是否展示 学历编号，资格证书，护照编码
     * 接受样式：（数组）
     * 【
     *    {id:30,name:"身份核验"......}
     * 】
     */
    fileterProject(projectArray) {
      for (let i = 0; i < projectArray.length; i++) {
        console.log(projectArray[i].api_name);
        if (projectArray[i].api_name == "educationInfo_query") {
          this.iscertId = true;
          Dialog.alert({
            message: "本次含有【学历】核验，请准备好学历编号，并正确填写。",
          }).then(() => {
            // on close
          });
        }
		if (projectArray[i].api_name == "workingCompany") {
		  this.isworkingCompany = true;
		}
        if (projectArray[i].api_name == "certQuery") {
          this.iscertCode = true;
        }
        if (projectArray[i].api_name == "yushanQueryService") {
          this.ispassport_number = true;
        }
      }
    },
    async ok() {
      await this.$router.push("/backrefuse");
      //拒绝授权
      this.clickno();
    },
    refuse() {
      this.ismsk = true;
    },

    modifyismsk(msg) {
      this.ismsk = false;
      this.go();
    },
    go() {
      if (this.lock == true) {
      } else {
        return;
      }
      this.lock = false;
      setTimeout(() => {
        this.lock = true;
      }, 2000);

      if (this.name == "" || this.name == undefined) {
        this.$toast.fail("请输入姓名");
        return;
      }

      // if (
      //   !/^[\u4e00-\u9fa5]+$/gi.test(this.name) ||
      //   (this.name + "").length < 2
      // ) {
      //   this.$toast("请输入正确姓名");
      //   return;
      // }

      if (this.mobile == "" || this.mobile == undefined) {
        this.$toast.fail("请输入手机号");
        return;
      } else if (!/^1[3-9]\d{9}$/.test(this.mobile)) {
        Dialog({
          message: "请输入正确手机号",
        });
        return;
      }
      if ((this.id_no + "").length < 16 || (this.id_no + "").length > 19) {
        this.$toast("请输入正确身份证号码");
        return;
      }

      //是否是人脸识别
      if (this.allmsg.auth_mode == 2) {
        const toast2 = Toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: "加载中，请稍后...",
        });
        this.$http
          .post("/firm/v1/face_recognition/faceRecognition", {
            reqType: "Background",
            mobile: this.mobile,
            id_no: this.id_no,
            name: this.name,
            report_id: this.allmsg.id,
            isqrcode: this.allmsg.isqrcode,
            certId: this.certId,
			conmpanyname:this.jobname,
            qr_id: this.allmsg.qrId,
            passport_number: this.passport_number,
            certCode: this.certCode,
            mobile_code: this.mobile_code, //验证是否是本人
            type: "1", //1-同意授权 2-拒绝授权
            id: this.allmsg.id,
            pay_type: this.allmsg.pay_type,
            num: this.allmsg.num, //支付金额
            idmsg: this.$route.params.idmsg, //再次人脸识别需要用到的id
          })
          .then((res) => {
            Toast.clear();
            if (JSON.parse(res.data).data.face_print) {
              window.location = JSON.parse(res.data).data.face_print;
            } else {
              this.$toast("获取人脸识别接口失败");
            }
          });
      } else {
        if (this.allmsg.isqrcode == true) {
          this.sendcodemsg();
        } else {
          this.go1();
        }
      }
    },
    /**
     * 同意授权
     */
    go1() {
      // if (!this.timeVerification()) return;
      const toast = Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "授权中，请稍后...",
      });
      setTimeout(() => {
        this.mobile_code = "";
      }, 2000);
      let url, msgobj;
      //是否是二维码模式扫进来得
      if (this.allmsg.isqrcode == true) {
        url = "/firm/v1/Login/setStatus2";
        msgobj = {
          reqType: "Background",
          name: this.name,
          id_no: this.id_no,
          qr_id: this.allmsg.qrId,
          mobile: this.mobile,
          passport_number: this.passport_number,
          certCode: this.certCode,
          certId: this.certId,
		  conmpanyname:this.jobname,
          mobile_code: this.mobile_code, //验证是否是本人
          type: "1", //1-同意授权 2-拒绝授权
          //传递过来的id
          id: this.allmsg.id,
        };
      } else {
        url = "/firm/v1/Login/setStatus1";
        msgobj = {
          reqType: "Background",
          name: this.name,
          id_no: this.id_no,

          mobile: this.mobile,
          passport_number: this.passport_number,
          certCode: this.certCode,
          certId: this.certId,
		  conmpanyname:this.jobname,
          type: "1", //1-同意授权 2-拒绝授权
          //传递过来的id
          id: this.allmsg.id,
        };
      }
      this.$http
        .post(url, msgobj)
        .then((res) => {
          this.lock = true;
          Toast.clear();
          sessionStorage.removeItem("backcenter_time");
          if (JSON.parse(res.data).code == "-88") {
            Dialog.alert({
              title: "提醒",
              message: "手机实名认证不通过,将切换到人脸识别!",
            }).then(() => {
              this.allmsg.auth_mode = 2; //当allmsg.auth_mode为2时为人脸识别否则为手机授权
              // this.go();
            });
            return;
          }
          // this.$router.push("/authorizationsuccess")
          //是谁来支付 为6得由候选人支付 不为6的话由发起背调的公司支付
          if (this.allmsg.pay_type == 6) {
            let aaa;
            //扫描的二维码进入
            if (this.allmsg.isqrcode == true) {
              this.$paymeth({
                money: this.allmsg.num,
                orderType: 8,
                url: base.url + "/authorizationsuccess",
                id: JSON.parse(res.data).data.l_id,
              });
            } else {
              //通过手机短信进入
              this.$paymeth({
                money: this.allmsg.num,
                orderType: 8,
                url: base.url + "/authorizationsuccess",
                id: this.allmsg.id,
              });
            }
          } else {
            this.$router.push("/authorizationsuccess");
          }
        })
        .catch((eer) => {
          this.lock = true;
        });

      // if (this.allmsg.pay_type == 6) {
      // } else {

      // }
    },
    /**
     * 拒绝授权
     */
    clickno() {
      let url, msgobj;
      if (this.allmsg.isqrcode == "true") {
        url = "/firm/v1/Login/setStatus2";
        msgobj = {
          reqType: "Background",
          name: this.name,
          id_no: this.id_no,
          qr_id: this.allmsg.qrId,
          mobile: this.mobile,
          passport_number: this.passport_number,
          certCode: this.certCode,
          certId: this.certId,
		  conmpanyname:this.jobname,
          type: "2", //1-同意授权 2-拒绝授权
          //传递过来的id
          id: this.allmsg.id,
        };
      } else {
        url = "/firm/v1/Login/setStatus1";
        msgobj = {
          reqType: "Background",
          name: this.name,
          id_no: this.id_no,
          mobile: this.mobile,
          passport_number: this.passport_number,
          certCode: this.certCode,
          certId: this.certId,
		  conmpanyname:this.jobname,
          type: "2", //1-同意授权 2-拒绝授权
          //传递过来的id
          id: this.allmsg.id,
        };
      }
      this.$http
        .post(url, msgobj)
        .then((res) => {
          this.lock = true;

          // this.$router.push("/authorizationsuccess")
          // this.$toast(JSON.parse(res.data).msg);
        })
        .catch((eer) => {
          this.lock = true;
        });
    },
  },
};
</script>
<style scoped>
.title {
  margin-top: 0.3rem;
  margin-left: 0.3rem;
  font-size: 0.36rem;
}

.tipcalss {
  color: #6f7070;
  font-size: 0.27rem;
  font-weight: 400;
  margin-left: 0.3rem;
  margin: 0.3rem;
}

.allclass >>> .van-button {
  min-width: 1.5rem;
}

.querenclass {
  text-align: center;

  margin: 0 1rem;
  margin-top: 1rem;
}

.allclass >>> .van-popup {
  border-radius: 0.3rem;
  padding: 0.5rem 0.1rem;
  min-width: 95%;
  color: white;
}

.allclass >>> .van-button__text {
  color: white;
}

.bton1 {
  width: 90%;
  color: #ffffff;
  margin: 0 5%;
  border: none;
  border-radius: 0.8rem;
  font-size: 0.36rem;
  padding: 0.2rem;
  height: 0.96rem;
}

.redmsg {
  font-weight: bold;
  color: #f65e19;
  font-size: 0.2rem;
}

.allclass {
  background-color: #ffffff;
  height: 100%;
}

span {
  color: #e14549;
  font-size: 0.28rem;
  line-height: 0.4rem;
}

input[disabled],
input:disabled,
input.disabled {
  background-color: white;
  -webkit-text-fill-background-color: white;
  -webkit-opacity: 1;
  opacity: 1;
}

.buall {
  display: flex;
}

.paytip {
  margin: 0.45rem 0.3rem;
  background-color: #fff2ed;
  color: #141f33;
  font-size: 0.28rem;
  padding: 0.26rem 0.3rem;
  font-weight: 500;
  border-radius: 10px;
}

.xinxi {
  display: flex;
  justify-content: space-between;
  margin: 0 0.3rem;
  padding: 0.21rem 0;
  border-bottom-color: #ebeef5;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  align-items: center;
}

.xinxi p {
  color: #616b80;
  font-size: 0.28rem;
}

.xinxi > div input {
  border: none;
}

.bt {
  margin-top: 2.83rem;
  margin-bottom: 1rem;
}

.buall >>> #bt1 {
  background-color: #616b80;
}

.xinxi input {
  text-align: right;
}

input {
  min-width: 5rem;
  font-size: 0.28rem;
}

input::placeholder {
  font-size: 0.28rem;
}

.allclass[data-v-5a296e7e] .van-popup {
  font-size: 0.4rem;
  color: red;
}

.allclass[data-v-5a296e7e] .van-popup[data-v-5a296e7e] {
  padding-top: 0.8rem;
}
</style>
